import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Rework – Book Review & Handpicked Notes",
  "author": "tzookb",
  "type": "post",
  "date": "2018-02-27T11:56:26.000Z",
  "url": "/2018/02/rework-book-review-handpicked-notes/",
  "categories": ["Uncategorized"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Something like 3 years ago I decided to start reading more books and this decision had a great impact on my life.`}</p>
    <p>{`With all that great knowledge I get to digest I want to start sharing my notes on all my reading so I`}{`’`}{`ll start with this AMAZING book who I finished in 3 hours!`}</p>
    <h3>{`Brief: {#brief}`}</h3>
    <p>{`Discover the world of tech businesses and learn how to operate properly as a founder and/or an employee.`}</p>
    <h3>{`Audience: {#audience}`}</h3>
    <p>{`Founders, CTO, managers, employees, basically it aims for tech businesses, but will surely benefit other verticals.`}</p>
    <h3>{`Bottom Line: {#bottomline}`}</h3>
    <p>{`You need to read it, it`}{`’`}{`s only 15$ and it will take you max 3 hours.`}</p>
    <h3>{`Notes: {#notes}`}</h3>
    <p>{`This book is divided to super small subjects of 2,4 pages each. It is super easy to digest and because it`}{`’`}{`s divided so well, you get to read them super fast and get `}{`“`}{`small wins`}{`”`}{` very quick for reading so much.`}</p>
    <h3>{`Takedowns: {#takedowns}`}</h3>
    <ul>
      <li parentName="ul">{`Work less time, work smart`}</li>
      <li parentName="ul">{`Don`}{`’`}{`t overplan`}</li>
      <li parentName="ul">{`Solve your own pains`}</li>
      <li parentName="ul">{`Ship fast, and improve along the way`}</li>
      <li parentName="ul">{`Have some `}{`“`}{`alone`}{`”`}{` time with no interruptions`}</li>
      <li parentName="ul">{`Quick wins is the best for you and your team`}</li>
      <li parentName="ul">{`Sleep`}</li>
      <li parentName="ul">{`Say NO by default`}</li>
      <li parentName="ul">{`Share your knowledge`}</li>
      <li parentName="ul">{`Find managers of one`}</li>
      <li parentName="ul">{`Own the bad news`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      